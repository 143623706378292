$(function() {

    /**
     *
     * Nav fill
     *
     */

    $(window).scroll(function () {

        var scrollTop = $(window).scrollTop();

        if (scrollTop > 240) {
            $('.nav-desktop').addClass('nav-desktop--fill');
            $('.nav-desktop__subnav').addClass('nav-desktop__subnav--fill');
        }
        else {
            $('.nav-desktop--fill').removeClass('nav-desktop--fill');
            $('.nav-desktop__subnav--fill').removeClass('nav-desktop__subnav--fill');
        }

    });

    /**
     *
     * Hero background(minify and copy to top)
     *
     */
    // var heroBg = (function IIFE(){
    //
    //     var ratio = 1920/972;
    //
    //     return function(width,height){
    //
    //         if( width < 1850 ){
    //             if( ratio <= width/height ){
    //
    //                 $('.hero').css('background-size', '100% auto');
    //                 return false;
    //             }
    //
    //             $('.hero').css('background-size', 'auto 100%');
    //
    //         }
    //         else {
    //             $('.hero').css('background-size', '100% auto');
    //         }
    //
    //
    //     };
    //
    // })();

    if (typeof window.heroBg === 'undefined') {
        window.heroBg = function() {
          var heroBg = (function IIFE(){

              var ratio = 1920/972;

              return function(width,height){

                  if( width < 1850 ){
                      if( ratio <= width/height ){

                          $('.hero').css('background-size', '100% auto');
                          return false;
                      }

                      $('.hero').css('background-size', 'auto 100%');

                  }
                  else {
                      $('.hero').css('background-size', '100% auto');
                  }


              };

          })();
        }
    }

    heroBg(window.innerWidth,window.innerHeight);

    /**
     *
     * Resize action
     *
     */

    $( window ).resize(function() {
        heroBg($( window ).width(), $( window ).height());
        footerPosition();
    });

    /**
     *
     * Menu toggler
     *
     */

    $('.nav-toggler__svg').on('click', function () {
        $('.nav-mobile__list').slideToggle();
    });

    /**
     *
     * Product list
     *
     */

    $('.button-anchor').on('click', function(){
        checkHash();
    });

    var hash;

    function checkHash() {

        setTimeout( function(){
            hash = document.URL.substr(document.URL.indexOf('#')+1);

            if( document.URL != hash ) {
                var $productAnchor = $('.product-list[data-title='+hash+']');
                $productAnchor.removeClass('product-list--hidden');
                $('.product-list').not($productAnchor).addClass('product-list--hidden');

                var $buttonAnchor = $('.button-anchor[data-title='+hash+']');
                $buttonAnchor.addClass('button--active');
                $('.button-anchor').not($buttonAnchor).removeClass('button--active');
            }

        }, 1);

    }
    checkHash();

    /**
     *
     * Quantity
     *
     */

    $(".button-quantity").on("click", function(e) {

        e.preventDefault();

        var $button = $(this);
        var oldValue = $button.parent().find("input").val();

        if ($button.text() == "+") {
            var newVal = parseFloat(oldValue) + 1;
        } else {

            if (oldValue > 0) {
                var newVal = parseFloat(oldValue) - 1;
            } else {
                newVal = 0;
            }
        }

        $button.parent().find("input").val(newVal);
        $button.parent().find("input").attr('value',newVal);

    });

    /**
     *
     * Accordion
     *
     */

    $('.accordion__title').on('click', function (e) {

        var $accordionTitle = $(e.target);
        var $next = $accordionTitle.next();
        $accordionTitle.toggleClass('open');
        $next.slideToggle();

    });

    /**
     *
     * Footer
     *
     */

    var footerPosition = (function footerPosition(){

        return function(){

            if ($( window ).height() > $('body').height()) {

                $('.footer').css({
                    'position': 'absolute',
                    'bottom': '0',
                    'width': '100%'
                });

            }

        }

    })();
    footerPosition();

    var selectShipping = (function (){

        return function(){

            ($('.woocommerce-checkout-review-order-table').find('input:checked')).each(function( index ){
                $this = $(this);
                ($this.parent()).show();

                var attrChecked = $this.attr('id');
                $el = $('#order_review').find('input[id="'+ attrChecked +'"]');    
                console.log($el);    
                $el.prop('checked', true);

            })

        }

    })();
    setTimeout(selectShipping, 2000);


    $('#order_review').on('click', function(e){

       setTimeout(selectShipping, 2000);

    })

});
